<template>
	<div class="modal">
		<ion-header>
			<ion-toolbar>
				<ion-title>Avatar ändern</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="dismissModal()">
						<ion-icon
							slot="icon-only"
							color="black"
							:icon="closeOutline"
						></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true">
			<ion-grid class="tabbar-b-margin">
				<ion-row>
					<ion-col v-for="avatar in avatare" :key="avatar.id" size="6">
						<ion-avatar @click="selectImg(avatar.id)">
							<img
								:src="imgLink(avatar.id)"
								:class="{ selected: selected === avatar.id }"
							/>
						</ion-avatar>
					</ion-col>
				</ion-row>
			</ion-grid>

			<ion-button
				expand="block"
				color="black"
				class="ion-margin avatarbtn"
				:disabled="selected === ''"
				@click="changeAvatar()"
				>Avatar ändern</ion-button
			>
		</ion-content>
	</div>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonAvatar,
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonGrid,
	IonRow,
	IonCol,
	modalController,
} from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';

export default {
	name: 'AvatareModal',
	props: {
		avatare: Array,
	},
	components: {
		IonContent,
		IonHeader,
		IonAvatar,
		IonTitle,
		IonToolbar,
		IonButtons,
		IonButton,
		IonIcon,
		IonGrid,
		IonRow,
		IonCol,
	},
	data() {
		return {
			selected: '',
		};
	},
	created() {
		this.selected = this.$store.state.user.avatar;
	},
	methods: {
		dismissModal() {
			modalController.dismiss();
		},
		selectImg(id) {
			this.selected = id;
		},
		imgLink(img) {
			return `${
				this.$store.state.imageBase
			}${img}?access_token=${localStorage.getItem('auth_token')}`;
		},
		async changeAvatar() {
			this.$store.dispatch('updateLoading', true);
			await this.$http.patch(`/users/${this.$store.state.user.id}/`, {
				avatar: this.selected,
			});
			this.$store.dispatch('fetchUser');
			this.$store.dispatch('updateLoading', false);
			modalController.dismiss();
		},
	},
	setup() {
		return { closeOutline };
	},
};
</script>

<style lang="scss" scoped>
.ion-page {
	background: white;
}

ion-avatar {
	margin: 0 auto;
	height: 6rem;
	width: 6rem;
}

ion-col {
	margin: 20px 0px;
}

.avatarbtn {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}

.selected {
	filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.249508));
	border: 2px solid var(--ion-color-primary);
}

ion-content::part(scroll) {
	padding-bottom: 30px;
}
</style>
