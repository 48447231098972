<template>
	<div class="modal">
		<ion-header>
			<ion-toolbar>
				<ion-title>Passwort ändern</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="dismissModal()">
						<ion-icon
							slot="icon-only"
							color="black"
							:icon="closeOutline"
						></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true">
			<ion-list lines="full">
				<ion-item>
					<ion-input
						placeholder="Altes Passwort"
						type="password"
						v-model="oldPW"
						required
					></ion-input>
				</ion-item>
				<ion-item>
					<ion-input
						placeholder="Neues Passwort"
						type="password"
						v-model="newPw"
						required
					></ion-input>
				</ion-item>
				<ion-item>
					<ion-input
						placeholder="Neues Passwort wiederholen"
						type="password"
						v-model="newPwConfirm"
						required
					></ion-input>
				</ion-item>
			</ion-list>
			<ion-button
				expand="block"
				color="black"
				class="ion-margin"
				@click="changePassword()"
			>
				Passwort ändern
			</ion-button>
		</ion-content>
	</div>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonList,
	IonItem,
	IonToolbar,
	IonButtons,
	IonButton,
	IonInput,
	IonIcon,
	modalController,
	alertController,
} from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';

export default {
	name: 'MaModal',
	data() {
		return {
			content: 'Content',
			oldPW: '',
			newPw: '',
			newPwConfirm: '',
		};
	},
	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonList,
		IonItem,
		IonInput,
		IonToolbar,
		IonButtons,
		IonButton,
		IonIcon,
	},
	methods: {
		dismissModal() {
			modalController.dismiss();
		},
		async changePassword() {
			// TODO: min length
			if (this.newPw === this.newPwConfirm) {
				try {
					this.$store.dispatch('updateLoading', true);

					const oldPw = this.oldPW;
					const email = this.$store.state.user.email;

					const login = await this.$store.dispatch('login', {
						email,
						password: oldPw,
					});

					if (login.status !== 200 && login.errors && login.errors.length > 0) {
						alert('Wrong Password');
						this.$store.dispatch('updateLoading', false);
					} else {
						await this.$http.patch(`/users/${this.$store.state.user.id}/`, {
							password: this.newPw,
						});
						this.$store.dispatch('updateLoading', false);
						const alert = await alertController.create({
						header: '🙌 Passwort erfolgreich geändert!',
						buttons: [
							{
								text: 'OK',
								role: 'cancel',
							},
						],
					});
					alert.present();
						modalController.dismiss();
					}
				} catch (e) {
					console.log(e);
					const alert = await alertController.create({
						header: '🙈 Das hat leider NICHT geklappt!',
						message: e,
						buttons: [
							{
								text: 'Abbrechen',
								role: 'cancel',
							},
						],
					});
					alert.present();
					this.$store.dispatch('updateLoading', false);
				}
			}
		},
	},
	setup() {
		return { closeOutline };
	},
};
</script>

<style lang="scss" scoped>
.ion-page {
	background: white;
}
</style>
