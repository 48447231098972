<template>
	<ion-page id="profil">
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-menu-button color="black"></ion-menu-button>
				</ion-buttons>
				<ion-title class="slabs">Profil</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="presentActionSheet">
						<ion-icon
							slot="icon-only"
							color="black"
							:icon="settingsOutline"
						></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true">
			<div class="header">
				<ion-avatar>
					<img :src="imgLink" class="avatar" />
				</ion-avatar>
			</div>
			<div class="content">
				<h1 class="slabs">
					{{ $store.state.user.first_name }}
					{{ $store.state.user.last_name }}
				</h1>

				<ion-grid class="tabbar-b-margin">
					<ion-row>
						<ion-col
							class="badge"
							v-for="badge in $store.state.user.badges"
							:key="badge"
							size="6"
							size-sm="6"
							size-xl="4"
						>
							<ion-avatar class="image">
								<img :src="badgeLink(badge.icon)" />
							</ion-avatar>
							<h3>{{ badge.name }}</h3>
						</ion-col>
					</ion-row>
				</ion-grid>
			</div>
		</ion-content>
		<TabBar></TabBar>
	</ion-page>
</template>

<script>
import {
	IonButtons,
	IonButton,
	IonContent,
	IonHeader,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonIcon,
	IonToolbar,
	IonAvatar,
	IonGrid,
	IonRow,
	IonCol,
	actionSheetController,
	modalController,
} from '@ionic/vue';
import { settingsOutline } from 'ionicons/icons';

import PwModal from '@/components/PwModal';
import AvatarModal from '@/components/AvatarModal';
import TabBar from '@/components/TabBar.vue';

export default {
	name: 'Profil',
	components: {
		IonButtons,
		IonButton,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonIcon,
		IonTitle,
		IonToolbar,
		IonAvatar,
		IonGrid,
		IonRow,
		IonCol,
		TabBar,
	},
	data() {
		return {
			avatare: [],
		};
	},
	computed: {
		imgLink() {
			return `${this.$store.state.imageBase}${
				this.$store.state.user.avatar ||
				'dcf0ce07-4687-4185-8589-b5f442200498'
			}?access_token=${localStorage.getItem('auth_token')}`;
		},
	},
	methods: {
		badgeLink(badge) {
			return `${
				this.$store.state.imageBase
			}${badge}?access_token=${localStorage.getItem('auth_token')}`;
		},
		async ionViewWillEnter() {
			const avatare = await this.$http.get(
				'files?filter[folder]=b35065b1-3273-43bc-bcc0-06052ee34047'
			);
			this.avatare = avatare.data.data;
		},
		async presentActionSheet() {
			const actionSheet = await actionSheetController.create({
				header: 'Einstellungen',
				cssClass: 'my-custom-class',
				buttons: [
					{
						text: 'Avatar ändern',
						handler: () => {
							this.openAvatarModal();
						},
					},
					{
						text: 'Passwort ändern',
						handler: () => {
							this.openPwModal();
						},
					},
					{
						text: 'Abbrechen',
						role: 'cancel',
						handler: () => {
							console.log('Cancel clicked');
						},
					},
				],
			});
			return actionSheet.present();
		},
		async openPwModal() {
			const modal = await modalController.create({
				component: PwModal,
			});
			return modal.present();
		},
		async openAvatarModal() {
			const avatare = this.avatare;
			const modal = await modalController.create({
				component: AvatarModal,
				componentProps: { avatare },
			});
			return modal.present();
		},
	},
	setup() {
		return {
			settingsOutline,
		};
	},
};
</script>

<style lang="scss" scoped>
#profil {
	.header {
		background: url(/assets/img/profilbg.jpg);
		height: 250px;
		background-position: top center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;

		ion-avatar {
			// border-radius: 100%;
			height: 7rem;
			width: 7rem;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -2.5rem;
			margin: 0 auto;
			background: white;
			filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.249508));
			overflow: hidden;
			img {
				margin-top: 0.3rem;
			}
		}
	}

	.content {
		h1 {
			margin-top: 3rem;
			text-align: center;
		}

		.badge {
			display: flex;
			justify-content: center;
			flex-direction: column;
			ion-avatar {
				margin: 0 auto;
			}
			text-align: center;
			width: 200px;

			h3 {
				font-size: 14px;
			}

			@media screen and (min-width: 768px) {
				h3 {
					font-size: 22px;
				}
			}
		}
	}
}
</style>
